import globalScope from "../../global"; // For tests, ugh

export async function login(oFormData: any) {
  const loginObj = {
    email: oFormData.email,
    password: oFormData.password,
    fbId: oFormData.fbId,
    fbToken: oFormData.fbToken,
    rememberMe: oFormData.remember ? oFormData.remember : "false",
  };
  const response = await fetch(
    `${global.apiHost}/api/v0/account/fastLogin?action=login`,
    {
      method: "POST",
      body: JSON.stringify(loginObj),
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }

  const account = await response.json();
  return account;
}

export async function checkCourtesyPassword(oFormData: any) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account/check_courtesy_password`,
    {
      method: "POST",
      body: JSON.stringify(oFormData),
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  );

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return;
}

export async function resetPassword(email: string) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account/passwordReset?email=` + email,
    { credentials: "include" },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
}

export async function changePassword(updateObj: any) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account/changePassword`,
    {
      method: "PUT",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updateObj),
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
}

export async function checkChangeToken(token: string) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account/checkChangeToken`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ token }),
      headers: {
        "Content-type": "application/json",
      },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return;
}

export async function findAccountById() {
  const response = await fetch(`${global.apiHost}/api/v0/account`, {
    credentials: "include", // need for httpOnly cookies to get set
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const account = await response.json();
  return await account;
}

export async function createAccount(account: any, acceptTos: boolean) {
  const response = await fetch(`${global.apiHost}/api/v0/account`, {
    method: "POST",
    body: JSON.stringify({
      account,
      acceptTos,
    }), // JSON.stringify need for body to send
    credentials: "include", // need for httpOnly cookies to get set
    headers: {
      "Content-Type": "application/json",
      timezone: Math.round(
        -1 * (new Date().getTimezoneOffset() / 60),
      ).toString(),
    }, // needed body to send
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}

export async function updateAccount(
  updateObj: any,
  acceptTos: boolean = false,
) {
  const response = await fetch(`${global.apiHost}/api/v0/account`, {
    method: "PUT",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ updateObj, acceptTos }),
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const account = await response.json();
  return await account;
}

export async function updateAvatar(profileImg: any, accountId: number) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account/saveAccountAvatar/${accountId}`,
    {
      method: "PUT",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ profileImg: profileImg }),
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const account = await response.json();
  return await account;
}

export async function cancelAccount() {
  const response = await fetch(`${global.apiHost}/api/v0/account/cancel`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}

export async function logViewCancelScreen() {
  const response = await fetch(
    `${global.apiHost}/api/v0/account/viewCancelScreen`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
}

export async function join({
  account,
  plan,
  nonce,
  promoCode,
  holidayCode,
  referralCode,
  billingUrl,
  shipBox,
  isExperiment29 = false,
  renewalPlan = null,
  isUpgrade,
  isUpsell = false,
  applePayNonce = null,
}: any) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account/enroll${shipBox ? "?shipBox" : ""}`,
    {
      method: "post",
      body: JSON.stringify({
        account,
        plan,
        payment: nonce,
        promoCode,
        holidayCode,
        referralCode,
        billingUrl,
        isExperiment29,
        renewalPlan,
        isUpgrade,
        isUpsell,
        applePayNonce,
      }),
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}

export async function changePolicy(
  policyId: number,
  overridePolicies = false,
  policy = null,
) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account/changePolicy/${policyId}?overridePolicies=${overridePolicies}`,
    {
      method: "put",
      body: JSON.stringify({ policy }),
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const account = await response.json();
  return await account;
}

export async function changeRenewalPlan(
  renewalPlanId: number,
  overridePlans = false,
  plans = null,
  acceptTos = false,
) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account/renewInto/${renewalPlanId}?overridePlans=${overridePlans}`,
    {
      method: "put",
      body: JSON.stringify({ plans, acceptTos }),
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const account = await response.json();
  return await account;
}

export async function changeMustRenewSaveOfferRenewalPlan(
  newPlanCredits: number,
) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account/changeMustRenewSaveOfferRenewalPlan`,
    {
      method: "put",
      body: JSON.stringify({ planCredits: newPlanCredits }),
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const account = await response.json();
  return await account;
}

export async function logout() {
  const response = await fetch(`${global.apiHost}/api/v0/account/logout`, {
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
}

export async function addHolidayCredit(holidayCode: string) {
  const response = await fetch(`${global.apiHost}/api/v0/account/credit`, {
    method: "put",
    body: JSON.stringify({ holidayCode }),
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const account = await response.json();
  return await account;
}

export async function createReferral(referObj: any) {
  const response = await fetch(`${global.apiHost}/api/v0/account/referral`, {
    method: "post",
    body: JSON.stringify(referObj),
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
}

export async function sendMemorialDayEmail(email: string, holidayCode: string) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account/sendHolidayEmail`,
    {
      method: "put",
      body: JSON.stringify({ email, holidayCode }),
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
}

export async function markMessageAsSeen(id: number) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account/messageStatus`,
    {
      method: "put",
      body: JSON.stringify(id),
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const account = await response.json();
  return await account;
}

export async function getReferrer(
  referCode: string,
  showBox = false,
  pdpId: any,
) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account/referral/${referCode}?referrer${
      showBox ? "&showBox=true" : ""
    }${pdpId ? `&pdpId=${pdpId}` : ""}`,
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const referrals = await response.json();
  return await referrals;
}

export async function getReferrals() {
  const response = await fetch(`${global.apiHost}/api/v0/account/referral/`, {
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const referrals = await response.json();
  return await referrals;
}

export async function checkEmail(email: string) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account/email/${email}`,
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const res = await response.text();
  return res.length ? JSON.parse(res) : null;
}

export async function getCreditHistory() {
  const response = await fetch(
    `${global.apiHost}/api/v0/account/credit_history/`,
    { credentials: "include" },
  );
  if (!response.ok) {
    {
      throw new Error(await response.text());
    }
  }
  const creditHistory = await response.json();
  return await creditHistory;
}

export async function sendCancelLink(name: string, email: string) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account/cancelLink?name=${name}&email=${email}`,
    { credentials: "include" },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
}

export async function checkCancelLinkToken(token: string) {
  const response = await fetch(
    `${global.apiHost}/api/v0/account/checkCancelLinkToken`,
    {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ token }),
      headers: {
        "Content-type": "application/json",
      },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
}

export async function skipRenewal(adjustment: number, pause = false) {
  //cc:skipRenewal#3
  //cc:pauseRenewal#3
  const response = await fetch(`${global.apiHost}/api/v0/account/skipRenewal`, {
    method: "PUT",
    body: JSON.stringify({
      adjustment,
      pause,
    }),
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return await response.json();
}

export async function authedLoginData(isFromSnesEnroll: boolean) {
  const loginRequest = await fetch(
    `${global.apiHost}/api/v0/account/login?isFromSnesEnroll=${isFromSnesEnroll}`,
    {
      credentials: "include",
    },
  );
  if (!loginRequest.ok) {
    throw new Error(await loginRequest.text());
  }
  return await loginRequest.json();
}
export async function getVisitorId() {
  const visitorId = await fetch(`${global.apiHost}/api/v0/account/visitorId`, {
    credentials: "include",
  });
  if (!visitorId.ok) {
    throw new Error(await visitorId.text());
  }
  return await visitorId.text();
}

export async function consentSMS(phoneNumber: string) {
  const response = await fetch(
    global.apiHost + "/api/v2/botm/account/smsConsent",
    {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify({ phoneNumber }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
      },
    },
  );
  return await response.json();
}

export async function markUnderThirteenAccount() {
  const response = await fetch(
    `${global.apiHost}/api/v0/account/underThirteen`,
    {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const account = await response.json();
  return await account;
}

export async function updateOccupation(occupation: string) {
  const response = await fetch(
    global.apiHost + "/api/v0/account/updateOccupation",
    {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify({
        occupation,
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
      },
    },
  );
  const account = await response.json();
  return await account;
}

export async function claimMustRenewSaveOffer() {
  const response = await fetch(
    global.apiHost + "/api/v0/account/claimMustRenewSaveOffer",
    {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
      },
    },
  );
  const account = await response.json();
  return await account;
}
