/**
 * App footer.
 * Child of BOM.jsx
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "connected-react-router";
import { withStoreData } from "../../ui/hoc/withStoreData";
import { logout } from "../../state/account/flows";
import { setOverlay } from "State/ui/creators";
import ClickTracker from "../../ui/elements/ClickTracker";
import ColorBlock from "./ColorBlock";
import DownloadButton from "./DownloadButton";
import InstagramIcon from "../../ui/elements/InstagramIcon";
import { snesRedirect } from "../../utils/snesRedirect";
import { Link as SCLink } from "../../ui/elements/navigation/Link";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.assembleFooterMenu = this.assembleFooterMenu.bind(this);
    this.logout = this.logout.bind(this);
  }

  logout() {
    this.props.logout();
  }

  assembleFooterMenu(variant) {
    let { storeData, isLoggedIn, isMember, isLead, appFeatures, isCanadaMode } =
        this.props,
      { store, track } = storeData,
      { currentCycle } = store;

    let pickABookColumn = (
      <ul className="itemList linkGroup" key={"pick"}>
        <li>
          <p className="label2 -bold">Pick a book</p>
        </li>
        <li className="footerNavLinksWrapper">
          <li>
            <a
              onClick={() => snesRedirect("snes", "/the-best-new-books")}
              tabIndex="0"
            >
              {`${currentCycle?.label || "This month’s"} books`}
            </a>
          </li>
          <li>
            <a
              onClick={() => snesRedirect("snes", "/all-hardcovers")}
              tabIndex="0"
            >
              All books
            </a>
          </li>
          {/* Temp hide BOTY link - track id should not be 12 - should be 1 */}
          {isMember && variant !== 0 && (
            <li>
              <ClickTracker
                ctaType={"navLink"}
                to={`/to-be-read`}
                title={`To be read`}
                logClickData={`Clicked - footer-tbr`}
              />
            </li>
          )}
          <li>
            <a
              onClick={() => snesRedirect("snes", "/best-books-of-the-year")}
              tabIndex="0"
            >
              Book of the Year
            </a>
          </li>
        </li>
      </ul>
    );

    let aboutBOTMColumn = (
      <ul className="itemList linkGroup" key={"botm"}>
        <li>
          <p className="label2 -bold">About BOTM</p>
        </li>
        <li className="footerNavLinksWrapper">
          <li>
            <a onClick={() => snesRedirect("snes", "/about-us")} tabIndex="0">
              About us
            </a>
          </li>
          <li>
            <ClickTracker
              ctaType={"navLink"}
              id="vbt"
              to={"/vbt"}
              title={"Virtual Book Tour"}
              logClickData={"Clicked - footer-vbt"}
            />
          </li>
          {isMember && (
            <li>
              <a href="https://www.volume0.com/">VOLUME Ø</a>
            </li>
          )}
          <li>
            <a href="https://wwd.com/pop-culture/culture-news/book-of-the-month-kaliane-bradley-ministry-of-time-brianna-goodman-1236412340/">
              Recent Press
            </a>
          </li>
          {isMember && (
            <li>
              <ClickTracker
                ctaType={"navLink"}
                id="help-center"
                to={"/help-center"}
                title={"Help center"}
                logClickData={"Clicked - footer-help-center"}
              />
            </li>
          )}
          {!isMember && (
            <li>
              <ClickTracker
                ctaType={"navLink"}
                id="bff-rewards"
                to={"/relationship-status"}
                title={"Relationship status"}
                logClickData={"Clicked - footer-relationship-status"}
              />
            </li>
          )}
          {!isMember && (
            <li>
              <a href="https://www.volume0.com/">VOLUME Ø</a>
            </li>
          )}
          {isMember && (
            <li>
              <ClickTracker
                ctaType={"navLink"}
                id="careers"
                to={"/careers"}
                title={"Careers"}
                logClickData={"Clicked - footer-careers"}
              />
            </li>
          )}
          {!isLoggedIn && (
            <li>
              <ClickTracker
                ctaType={"navLink"}
                id="footer-join-link"
                to={this.props.entryPoint !== "leadEmail" ? null : "/step-join"}
                handleClick={() => {
                  if (this.props.entryPoint !== "leadEmail") {
                    this.props.setOverlay("signUpModal");
                  }
                }}
                title={"Sign up"}
                logClickData={"Clicked - footer-signup"}
              />
            </li>
          )}
          {isLead && (
            <li>
              <ClickTracker
                ctaType={"navLink"}
                id="join-billing"
                to={"/join-billing"}
                title={"Join"}
                logClickData={"Clicked - footer-join-billing"}
              />
            </li>
          )}
          {isLead && (
            <li>
              <ClickTracker
                ctaType={"navLink"}
                id="log-out"
                handleClick={this.logout}
                title={"Log out"}
                logClickData={"Clicked - footer-log-out"}
              />
            </li>
          )}
        </li>
      </ul>
    );

    let giftsColumn =
      track.id == 1 ? (
        <ul className="itemList linkGroup" key={"gift"}>
          <li>
            <p className="label2 -bold">Gifts</p>
          </li>
          <li className="footerNavLinksWrapper">
            <li>
              <a onClick={() => snesRedirect("snes", "/gift")} tabIndex="0">
                Give a gift
              </a>
            </li>
            <li>
              <ClickTracker
                ctaType={"navLink"}
                id="redeem-a-gift"
                to={"/gift/redeem"}
                title={"Redeem a gift"}
                logClickData={"Clicked - footer-redeem-gift"}
              />
            </li>
            {track.id == 1 && !isCanadaMode ? (
              <li>
                <a
                  onClick={() => snesRedirect("snes", "/corporate")}
                  tabIndex="0"
                >
                  Group gifting
                </a>
              </li>
            ) : null}
          </li>
        </ul>
      ) : null;

    let getInTouchColumn = (
      <ul className="itemList linkGroup" key={"intouch"}>
        <li>
          <p className="label2 -bold">Get in touch</p>
        </li>
        <li className="footerNavLinksWrapper">
          {!isMember && (
            <li>
              <ClickTracker
                ctaType={"navLink"}
                id="help-center"
                to={"/help-center"}
                title={"Help center"}
                logClickData={"Clicked - footer-help-center"}
              />
            </li>
          )}
          <li>
            <ClickTracker
              ctaType={"navLink"}
              id="careers"
              to={"/join-our-team"}
              title={"Careers"}
              logClickData={"Clicked - footer-careers"}
            />
          </li>
        </li>
      </ul>
    );

    let yourMembershipColumn = (
      <ul className="itemList linkGroup" key={"membership"}>
        <li>
          <p className="label2 -bold">My Membership</p>
        </li>
        <li className="footerNavLinksWrapper">
          <li>
            <ClickTracker
              ctaType={"navLink"}
              id="my-account"
              to={"/my-account/account"}
              title={"Account"}
              logClickData={"Clicked - footer-my-account"}
            />
          </li>
          <li>
            <ClickTracker
              ctaType={"navLink"}
              id="bff-rewards"
              to={"/relationship-status"}
              title={"Relationship status"}
              logClickData={"Clicked - footer-relationship-status"}
            />
          </li>
          <li>
            <ClickTracker
              ctaType={"navLink"}
              id="refer-a-friend"
              to={"/refer-a-friend"}
              title={"Refer a friend"}
              logClickData={"Clicked - footer-refer-a-friend"}
            />
          </li>
          {isMember && (
            <li>
              <ClickTracker
                ctaType={"navLink"}
                id="log-out"
                handleClick={this.logout}
                title={"Log out"}
                logClickData={"Clicked - footer-log-out"}
              />
            </li>
          )}
        </li>
      </ul>
    );
    return isMember
      ? [pickABookColumn, yourMembershipColumn, giftsColumn, aboutBOTMColumn]
      : [pickABookColumn, aboutBOTMColumn, giftsColumn, getInTouchColumn];
  }

  render() {
    const noNavPages = ["enroll", "rejoin", "member-offer", "lead"];
    const stickyFooterPages = ["my-box"]; // url paths for which there may be a sticky footer (like "Ship Now" button) on mobile
    let { storeData, isEnroll, path, isMember, isLoggedIn } = this.props,
      { track } = storeData;
    let rootPath = path.split("/"),
      noNav = noNavPages.indexOf(rootPath[1]) > -1,
      pageHasStickyFooter = stickyFooterPages.indexOf(rootPath[1]) > -1;

    return (
      <footer
        id="footer"
        className={`footer ${isEnroll ? "forEnrollFlow" : " "} ${
          pageHasStickyFooter ? "stickyFooterSpacing" : ""
        }`}
      >
        <div className="forEnrollMobile">
          <div className="rightSideWrapper ">
            <ul className="itemList linkGroup">
              <li>
                <ClickTracker
                  ctaType={"navLink"}
                  id="support"
                  to="/help-center"
                  title={"Support"}
                  logClickData={"Clicked - footer-help-center"}
                />
              </li>
              &nbsp;/&nbsp;
              <li>
                <ClickTracker
                  ctaType={"navLink"}
                  id="redeem-a-gift"
                  to="/gift/redeem"
                  title={"Redeem a gift"}
                  logClickData={"Clicked - footer-redeem-gift"}
                />
              </li>
              &nbsp;/&nbsp;
              <li>
                <a onClick={() => snesRedirect("snes", "/terms-of-membership")}>
                  Terms of use
                </a>
              </li>
            </ul>
          </div>
        </div>

        {(track.id == 1 || (track.id == 2 && isMember)) && (
          <div className={"tier1" + (noNav ? " forEnrollMobile" : "")}>
            <div className="footerNavContentWrapper">
              <div>
                <h3 className="h3">
                  Fiction, <span className="footerNavHelper">forward.</span>
                </h3>
              </div>
              <div className="rightSideWrapper">
                <div className="footerNavColumnsWrapper">
                  {this.assembleFooterMenu()}
                </div>
                <div className="socialWrapper">
                  <div className="instagramWrapper">
                    <InstagramIcon />
                    <div className="instagramLinkWrapper">
                      <ClickTracker
                        ctaType={"link"}
                        id="link-footer-instagram"
                        handleClick={() =>
                          window.open(
                            "https://www.instagram.com/bookofthemonth/",
                            "_blank",
                          )
                        }
                        title={"Instagram"}
                        logClickData={"Clicked - Instagram"}
                      />
                    </div>
                  </div>
                  <div className="iconWrapper">
                    <DownloadButton type="apple" />
                  </div>
                  <div className="iconWrapper">
                    <DownloadButton type="google" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <ColorBlock />
        <div className="tier2">
          <div className="tier2Content">
            <div className="finePrint p5">
              &copy;2023 Book of the Month LLC. Book-of-the-Month and
              Book-of-the-Month Club are registered trademarks of Book of the
              Month LLC.
            </div>
            <div className="finePrintWrapper">
              <ClickTracker
                ctaType={"link"}
                id="accessibility-statement"
                linkTo="/accessibility-statement"
                title={"Accessibility statement"}
                logClickData={"Clicked - footer-accessibility-statement"}
              />
              &nbsp;&nbsp;&nbsp;
              <SCLink
                id="privacy-policy"
                linkTo="/privacy-policy"
                navigate={() => snesRedirect("snes", "/privacy-policy")}
              >
                Privacy Policy
              </SCLink>
              &nbsp;&nbsp;&nbsp;
              <SCLink
                id="terms-of-membership"
                linkTo="/terms-of-membership"
                navigate={() => snesRedirect("snes", "/terms-of-membership")}
              >
                Terms of use
              </SCLink>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

function mapStateToProps(state) {
  const { appFeatures } = state;
  const loyaltyTierFeature = appFeatures?.find(
    (f) => f?.feature == "Loyalty Tier",
  );
  return {
    isLoggedIn: state.policy.type !== "Visitor",
    isLead: state.account && state.policy.type == "Lead",
    isMember:
      state.account &&
      state.policy.type !== "Visitor" &&
      state.policy.type !== "Lead",
    path: state.analytics ? state.analytics.location : "",
    appFeatures: state.appFeatures,
    entryPoint: state.entryPoint,
    loyaltyTierFeature,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ logout, push, setOverlay }, dispatch);
}

export default withStoreData(
  connect(mapStateToProps, mapDispatchToProps)(Footer),
);
