import globalScope from "../../global"; // For tests, ugh

export async function clear() {
  const response = await fetch(`${global.apiHost}/api/v2/botm/box?clear`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const box = await response.json();
  return await box;
}

export async function get() {
  const response = await fetch(`${global.apiHost}/api/v2/botm/box`, {
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const box = await response.json();
  return await box;
}

export async function addToBox(
  productId: number,
  experiment = [],
  promoProducts = null,
) {
  const response = await fetch(
    `${global.apiHost}/api/v2/botm/box/${productId}?add`,
    {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        promoProducts,
        experiment,
      }),
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const box = await response.json();
  return await box;
}

export async function removeFromBox(productId: number) {
  const response = await fetch(
    `${global.apiHost}/api/v2/botm/box/${productId}?remove`,
    {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const box = await response.json();
  return await box;
}

export async function makeSelection(productId: number) {
  const response = await fetch(
    `${global.apiHost}/api/v2/botm/box/${productId}?selection`,
    {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const box = await response.json();
  return await box;
}

export async function ship(acceptTos = false) {
  const response = await fetch(`${global.apiHost}/api/v2/botm/box?ship`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ acceptTos }),
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const box = await response.json();
  return await box;
}

export async function skip(acceptTos = false) {
  const response = await fetch(`${global.apiHost}/api/v2/botm/box?skip`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ acceptTos }),
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const box = await response.json();
  return await box;
}

export async function unskip() {
  const response = await fetch(`${global.apiHost}/api/v2/botm/box?unskip`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const box = await response.json();
  return await box;
}

export async function updatePaymentType(
  productId: number,
  paymentType: string, // looks like this isn't ah payment type
) {
  const response = await fetch(
    `${global.apiHost}/api/v2/botm/box/${productId}?paymentType`,
    {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ paymentType }),
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const box = await response.json();
  return await box;
}

export async function delayedShipConf() {
  const response = await fetch(
    `${global.apiHost}/api/v2/botm/box?delayedShipConf`,
    {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const box = await response.json();
  return await box;
}

export async function addPromoAndProduct(promoId: number, memo: string) {
  const response = await fetch(
    `${global.apiHost}/api/v2/botm/box/promo/${promoId}`,
    {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ memo }),
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const box = await response.json();
  return await box;
}

export async function getStatus(cycleId: number | null = null) {
  const response = await fetch(
    `${global.apiHost}/api/v2/botm/box/box_status${
      cycleId ? "/" + cycleId : ""
    }`,
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        timezone: Math.round(
          -1 * (new Date().getTimezoneOffset() / 60),
        ).toString(),
      },
    },
  );
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const boxStatus = await response.json();
  return await boxStatus;
}
